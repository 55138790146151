
.file-preview {
  display: flex;
  align-items: center;
  gap: 12px;
}

.preview, .preview-stub {
  width: 50px;
  height: 50px;
}

.preview {
  border-radius: 8px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.name {
  max-width: 20vw;
  color: #515C72;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.size {
  color: #6A7B95;
  font-size: 12px;
}
