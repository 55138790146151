@import '@/core/styles/mixins';

.menu-item {
  + .menu-item {
    margin-top: rem(8px);
  }

  &--children {
    &:first-child {
      border-top: 1px solid #E9EBF3;
      padding-top: rem(24px);
      margin-top: rem(24px);
    }

    &:last-child {
      border-bottom: 1px solid #E9EBF3;
      padding-bottom: rem(24px);
    }
  }

  &__icon {
    max-width: rem(24px);
    flex: 1 0 24px;
  }

  &__title {
    flex: 0 1 auto;
  }

  &__link {
    border-radius: rem(8px);
    align-items: center;
    position: relative;
    padding: rem(8px);
    color: #515C72;
    cursor: pointer;
    display: flex;
    text-decoration: none;

    &.router-link-active {
      color: #22C951;
    }

    &.is-expandable {
      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6667 5.33325L7.80467 8.19525L4.94267 5.33325L4 6.27592L7.80467 10.0806L11.6093 6.27592L10.6667 5.33325Z' fill='%23191B23'/%3E%3C/svg%3E%0A");
        background-position: center center;
        transition: transform 0.3s linear;
        background-repeat: no-repeat;
        transform: rotate(-90deg);
        position: absolute;
        height: rem(16px);
        width: rem(16px);
        right: rem(12px);
        margin: auto;
        content: '';
        bottom: 0;
        top: 0;
      }

      &.is-expanded {
        &:after {
          transform: rotate(0deg);
        }
      }
    }

    &:hover,
    &.is-expanded {
      background-color: #F0F6F9;
    }

    .svg-icon {
      margin-right: rem(12px);
      height: rem(24px);
      width: rem(24px);
    }
  }

  &__subtitle {
    padding: 8px 8px 8px 44px;
    margin-top: rem(8px);
    color: #515C72;
    display: block;

    &.router-link-active {
      color: #22C951;
    }
  }
}
